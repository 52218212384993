<template src="@ext/terms/gdpr_en.html">

</template>

<script>
export default {
    name: 'GDPREN',
    data: function () {
        return {
            columns: [
                { name: 'name', required: true, label: 'Tip document', align: 'left', field: row => row.name, format: val => `${val}`, sortable: false },
                { name: 'other', align: 'left', label: 'Număr, denumire, hyperlink', field: 'other', sortable: false },
            ],
            docs: [
                { name: 'Regulamentul UE', other: '2016/679 - Regulamentul UE privind protecția persoanelor fizice in ceea ce privește prelucrarea datelor cu caracter personal și privind libera circulație a acestor date și de abrogare a Directivei 95/46/CE' },
                { name: 'Lege', other: '677/2001 – Lege pentru protecția persoanelor cu privire la prelucrarea datelor cu caracter personal și libera circulație a acestor date' }
            ]
        }
    },
    created() {
        this.getBusiness()
    }
}
</script>

<template>
<div class="app flex-row align-items-center container-wrapper">
    <div class="container col-md-12 col-lg-6 pt-3 pb-3 pt-md-4 pb-md-4">
        <b-row class="m-0 justify-content-center shadow-2">
            <b-col class="p-0 bg-white">
                <q-card no-body class="p-4 full-height register-form" light>
                    <AppHeader class="header-lang no-border-bottom pl-3 pr-1 mb-0" :style="{position: 'relative', marginTop: '-1rem'}">
                        <switch-lang></switch-lang>
                    </AppHeader>
                    <div class="full-width navbar-brand text-center pt-0">
                        <router-link :to="{name: 'Home'}">
                            <img class="navbar-brand-full" :src="appLogo()" height="70" alt="Dynamic Studio Logo">
                        </router-link>
                    </div>
                    <q-tabs dense clickable hoverable no-caps v-model="tab" class="no-controls mb-1 small">
                        <q-tab class="pl-2 pr-2 text-dark" name="tac">
                            {{ translate('title_terms_and_conditions') }}
                        </q-tab>
                        <q-tab class="pl-2 pr-2 text-dark" name="rules_of_procedure">
                            {{ translate('rules_of_procedure') }}
                        </q-tab>
                        <q-tab class="pl-2 pr-2 text-dark" name="gdpr">
                            {{ translate('gdpr') }}
                        </q-tab>
                    </q-tabs>
                    
                    <template v-if="$appSettings.lang === 'ro'">
                        <template v-if="tab === 'tac'">
                            <tac-ro />
                        </template>
                        <template v-if="tab === 'rules_of_procedure'">
                            <rules-of-procedure-ro />
                        </template>
                        <template v-if="tab === 'gdpr'">
                            <gdpr-ro />
                        </template>
                    </template>
                    <template v-if="$appSettings.lang === 'en'">
                        <template v-if="tab === 'tac'">
                            <tac-en />
                        </template>
                        <template v-if="tab === 'rules_of_procedure'">
                            <rules-of-procedure-en />
                        </template>
                        <template v-if="tab === 'gdpr'">
                            <gdpr-en />
                        </template>
                    </template>

                    <q-tabs dense clickable hoverable no-caps v-model="tab" class="no-controls mb-3 small">
                        <q-tab class="pl-2 pr-2 text-dark" name="tac">
                            {{ translate('title_terms_and_conditions') }}
                        </q-tab>
                        <q-tab class="pl-2 pr-2 text-dark" name="rules_of_procedure">
                            {{ translate('rules_of_procedure') }}
                        </q-tab>
                        <q-tab class="pl-2 pr-2 text-dark" name="gdpr">
                            {{ translate('gdpr') }}
                        </q-tab>
                    </q-tabs>

                    <template v-if="$appSettings.currentUserType() === 'guest'">
                        <div class="text-center">
                            <q-btn :to="{name: 'Login'}" class="full-width mt-2 mb-2 no-text-decoration icon-right-leftspace" icon-right="login" color="info" square no-caps type="button">{{ translate('login') }}</q-btn>
                        </div>

                        <div class="full-width text-center">
                            <p class="mb-3 mt-2">
                                {{ translate('register_here') }}
                            </p>
                            <q-btn icon-right="send" color="dark" square no-caps :to="{name: 'Register'}" type="button" class="full-width no-text-decoration icon-right-leftspace">{{ translate('register') }}</q-btn>
                        </div>
                    </template>
                </q-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import {
    Header as AppHeader
} from '@coreui/vue'
import LangNavbarAuth from './LangNavbarAuth'
import TermsAndConditionsRO from '../terms-and-conditions/TermsAndConditionsRO.vue'
import TermsAndConditionsEN from '../terms-and-conditions/TermsAndConditionsEN.vue'
import RulesOfProcedureRO from '../terms-and-conditions/RulesOfProcedureRO.vue'
import RulesOfProcedureEN from '../terms-and-conditions/RulesOfProcedureEN.vue'
import GDPRRO from '../terms-and-conditions/GDPRRO.vue'
import GDPREN from '../terms-and-conditions/GDPREN.vue'

export default {
    name: 'TermsAndConditions',
    components: {
        AppHeader,
        'switch-lang': LangNavbarAuth,
        'tac-ro': TermsAndConditionsRO,
        'tac-en': TermsAndConditionsEN,
        'rules-of-procedure-ro': RulesOfProcedureRO,
        'rules-of-procedure-en': RulesOfProcedureEN,
        'gdpr-ro': GDPRRO,
        'gdpr-en': GDPREN,
    },
    data: function () {
        return {
            tab: 'tac'
        }
    },
}
</script>

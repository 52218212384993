<template src="@ext/terms/rules_en.html">

</template>

<script>
export default {
    name: 'RulesOfProcedureEN',
    created() {
        this.getBusiness()
    }
}
</script>

<template src="@ext/terms/rules_ro.html">

</template>

<script>
export default {
    name: 'RulesOfProcedureRO',
    created() {
        this.getBusiness()
    }
}
</script>

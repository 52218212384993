<template src="@ext/terms/terms_ro.html">

</template>

<script>
export default {
    name: 'TermsAndConditionsRO',
    created() {
        this.getBusiness()
    },
}
</script>
